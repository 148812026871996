import React from "react";
import {Link} from "gatsby"
import Logo from "./logo"
const Nav = (props) => {
    return(
        <nav className={`w-full mx-auto text-xl md:text-2xl text-black z-5 flex justify-between items-center py-4 md:py-8 px-4 top-0 left-0 z-10 ${props.sticky ? `fixed`: `absolute`}`}>
            <div className="max-w-7xl mx-auto flex justify-between items-center w-full md:px-4">
            <Link to={`/`} className='text-left'>
            <Logo className='h-6'/>

            </Link>

            <Link to={`/offerings`} className='text-left'>
                Our Offerings
                </Link>

            </div>

        </nav>
    )
}

export default Nav