import React, {useEffect, useState} from "react";
import Nav from "./nav";
import { Link } from "gatsby";


function Layout(props) {

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    setSticky(props.sticky)
  }, [])

  return (
    <div className="min-h-screen font-sans text-gray-900">
      <Nav sticky={sticky}/>
      <main>
        {props.children}
      </main>
      <footer className="py-8 border-t border-black text-lg space-y-4 md:space-y-0">
        <div className="max-w-7xl w-full mx-auto flex flex-col md:flex-row items-center md:justify-between px-4">
        <a className="underline" href="https://g.page/collective-100" target="_blank" rel="noreferrer">
        100 Cubitt Street Cremorne 
        </a>
        <Link to="/contact">
          Contact
        </Link>
        <a className="underline" href="mailto:info@collective100.com.au">
        info@collective100.com.au
        </a>

        </div>
      </footer>
    </div>
  );
}

export default Layout;
